<template>
  <v-layout align-center justify-center>
    <v-card elevation="5" width="400px">
      <v-toolbar dark flat color="primary">
        <v-toolbar-title>Login</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="formValid">
          <v-text-field
            v-model="userId"
            :rules="userIdRules"
            autofocus
            required
            prepend-icon="mdi-account"
            label="아이디"
          ></v-text-field>
          <v-text-field
            v-model="userPw"
            :rules="userPwRules"
            required
            type="password"
            prepend-icon="mdi-lock"
            label="비밀번호"
            @keydown.enter="login"
          ></v-text-field>
        </v-form>
        <p class="deep-orange--text text-right" v-if="errorMessage">{{errorMessage}}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn block color="primary" @click="login">로그인</v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      userId: '',
      userPw: '',
      formValid: false,
      errorMessage: null,
      userIdRules: [
        v => !!v || '아이디를 입력해 주세요'
      ],
      userPwRules: [
        v => !!v || '비밀번호를 입력해 주세요'
      ]
    }
  },
  methods: {
    login() {
      if (this.formValid) {
        this.$store.dispatch('sign/signIn', {userId: this.userId, userPw: this.userPw})
        .then(() => {
          this.$router.push('/').catch(() => {})
        })
        .catch(error => {
          if (error && error.response) {
            this.errorMessage = error.response.data
          } else {
            this.errorMessage = error.message
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.login-header {
  background-color: #337ab7;
}
</style>
